/**
 * 限时秒杀活动模型
 * @returns {{spikeGoodsList: Array, discountFee: number, supplierId: number, isDelete: number, payOrderNum: number, updateTime: number, payFee: number, createTime: number, isNotice: number, name: string, payGoodsNum: number, startTime: number, endTime: number, id: number, status: number}}
 */
export function returnBaseDataSecondsKill() {
  return {
    "createTime": 0,
    "discountFee": 0, // 优惠总金额
    "endTime": 0, // 秒杀结束时间
    "id": "",
    "isDelete": 0,  // 是否已删除，1、未删除，2、已删除
    "isNotice": 1,  //  活动是否预告，1、是，2、否
    "name": "", //  活动名称
    "payFee": 0,  //  订单实付金额
    "payGoodsNum": 0, //  付款商品件数
    "payOrderNum": 0, //  支付订单数
    "spikeGoodsList": [ //  秒杀活动商品列表
      // returnBaseDataSpikeGoodsInfo(),
    ],
    "startTime": 0, //  秒杀开始时间
    "status": 0,  // 秒杀状态：1、未开始 2、进行中 3、已结束 4、失效
    "supplierId": 0,  //  商户id
    "updateTime": 0, //  更新时间
    "isPurchaseLimit": 0, // 限购类型 0-不限购 1-数量限购 2-超出数量原价
    "purchaseLimitNum": 0,
    /*
    *  前端编辑使用的自定义字段
    * */
    "goodsSkuList":[ //商品列表 （纬度：sku）
      // returnBaseDataSpikeGoodsBasisInfo()
    ],
  }
}

/**
 *  秒杀活动商品信息 （纬度：商品ID）
 * @returns {{goodsId: number, goodsCover: string, spikeGoodsBasisList: Array, goodsName: string}}
 */
export function returnBaseDataSpikeGoodsInfo() {
  return {
    "goodsCover": "",// 商品封面图
    "goodsId": "",// 商品ID
    "goodsName": "", // 商品名称
    "spikeGoodsBasisList": [ // 秒杀活动sku列表
      // returnBaseDataSpikeGoodsBasisInfo(),
    ]
  }
}

/**
 * 秒杀活动商品基础信息 （纬度：sku ID）
 * */
export function returnBaseDataSpikeGoodsBasisInfo() {
  return {
    "costPercent": 0, // 平台扣点百分比
    "createTime": 0, // 创建时间
    "goodsId": "", // 商品id
    "goodsSkuId": 0, // 商品skuId
    "goodsSkuName": "", // 规格名称,颜色>白色；尺寸>s码
    "id": "",
    "isDelete": 0, // 是否已删除，1、未删除，2、已删除
    "originalPrice": 0, // // 原售价
    "originalPurchasePrice": 0, // 原供货成本价
    "price": 0, // 秒杀售价（促销价）
    "purchasePrice": 0, // 秒杀供货价
    "spikeId": 0, // 限时秒杀id
    "stockNum": 0, // 库存
    "supplierId": 0, // 商户id
    "updateTime": 0 // 更新时间
  }
}
